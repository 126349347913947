












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import vertragsdaten from '@/components/form/vertragsdaten-mashine.vue';
import IVertragsfaten from '@/model/IVertragsfaten';
import maschinenartValues from '@/config/maschinenart-values';
import maschinenartValuesStationar from '@/config/maschinenart-values-stationar';
import maschinenartValuesFahrbar from '@/config/maschinenart-values-fahrbar';
import selbstbeteiligungValues from '@/config/selbstbeteiligung-values';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { unselectFirstElement,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  duplicateIndividualPositionsdatenItems,
  copyObject,
  recursiveFillData,
  onCurrencyFocus,
  downloadFile,
  generateUniqueAmsId,
  createXMLKeys } from '@/helper/functions';
import apiBackend from '@/service/api-insurance';
import { fillCalcPriceResponse, getSum371, getSumVersicherunswert } from '@/helper/priceCalculator/individual-calculator';
import priceStatusIcon from '@/components/form/price-status-icon.vue';
import { exportToPDF, saveZusammenfassungPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import FormSwitch from '@/components/form/form-switch.vue';
import { INDIVIDUAL_INSURANCE_KEY,
  MACHINE_FAHRBAR,
  MACHINE_FULL_COVERAGE,
  MACHINE_STATIC_VALUE,
  MACHINE_STATIONAR,
  pramienSelbstbehaltsvariantenItemType } from '@/constant/constants';
import watchers from '@/watchers/change-data-for-price-request/individual-insurance/calc-tab';
import VersicherungsortRow from '@/views/components/individual-insurance/versicherungsort-row.vue';

const erstrisikosummenItem = {
  name: '',
  valueERS: '',
  valueGeanderteERS: 0,
  valueBeitragssatz: '',
  valueStatic: false,
};

const additionalParam = {
  name: 'test',
  value: 0,
};

const vertragsbezogeneZuschlageItem = {
  Regressverzicht: '',
  MitversicherungInnereUnruhen: '',
  calculat: false,
  additionalParams: [
    {
      ...additionalParam,
    },
  ],
};
const hiddenXMLkeys = {
  AMSPK: generateUniqueAmsId(),
  AMSIDNR: generateUniqueAmsId(),
  fillFromAMS: false,
};
const hiddenXmlValues = {
  deductibleFixed: null,
  deductiblePercentage: null,
  deductibleMin: null,
  deductibleMax: null,
};
const positionsdatenItem = {
  isSelected: false,
  groupNum: null,
  num: 0,
  Maschinenart: {
    label: '',
    value: '',
  },
  MKZ: '',
  Versicherungsort: '',
  LandwirtschaftlicherStillstandsrabatt: null,
  Beschreibung: '',
  Bewertungsjahr: new Date().getFullYear(),
  Versicherunswert: 0,
  FestwertGleitend: 'Gleitend (G)',
  BU: '',
  Pramiensatz: '',
  VSU: 0,
  Faktor: '',
  Deckungsform: '',
  hideSB: 0,
  SB: 0,
  SBpercent: null,
  SBmin: null,
  SBmax: null,
  Neuheitsrabatt: null,
  AusschlussKraftKaskoRisiko: null,
  Spindelklausel: false,
  StationareMaschinenMobilEingesetzt: '',
  StationareMaschinenMobilEingesetztPercent: 0,
  MitversicherungFeuerInMASS: null,
  MitversicherungUberschwemmungInMASS: null,
  MitversicherungErdbebenInMASS: null,
  Diebstahlrisiko: null,
  SbPercent: null,
  SbMin: null,
  SbMax: null,
  EinsatzImAusland: null,
  SchadenDurchVuV: null,
  EinsatzUnterTage: null,
  VermietungUndVerpachtung: '',
  GAPDeckung: false,
  UnterschlagungFOTGerate: false,
  VandalismusMTV: false,
  individualDiscount: 0,
  stillstandsrabatt: 0,
  individualSurcharge: 0,
  BeitragVar1: 0,
  BeitragVar2: 0,
  BeitragVar3: 0,
  BeitragVarRabattfaktor1: null,
  BeitragVarRabattfaktor2: null,
  BeitragVarRabattfaktor3: null,
  maschinenartBeschreibungOpen: true,
  mkzOpen: true,
  rabatteOpen: false,
  beitragOpen: false,
  SbTarifRabattfaktor: null,
  SbTarif: 0,
  Hersteller: null,
  Seriennummer: null,
  Baujahr: null,
  Typ: null,
  keysForXML: copyObject(hiddenXMLkeys),
  hiddenXmlValues: copyObject(hiddenXmlValues),
  showIndividualSurchargeMessage: false,
  individualSurchargeMessage: 'Hinweis: Abstimmung mit RFL oder BKO erforderlich!',
};

const geltungsbereicheItem = {
  isSelected: false,
  num: 0,
  strasse: '',
  plz: '',
  ort: '',
  land: '',
  hausnummer: '',
  zuers: 0,
  starkregenzone: '',
  naturgefahren2: '',
  naturgefahren3: '',
  geltungsbereich: '',
  individualAgreements: '',
};
const pramienSelbstbehaltsvariantenItem = {
  isSelected: false,
  SB: null,
  SBpercent: null,
  SBmin: null,
  SBmax: null,
  SBRabattfaktor: '',
  TarifpramiensatzAktuellNetto: '',
  Tarifpramiensatz: '',
  TarifpramieNetto: '',
} as pramienSelbstbehaltsvariantenItemType;
interface PriceItem {
  machineCodeNumber: string;
  premiumFactor: string;
  sumFactor: string;
  codeSFPF: string;
  codeABMGDiebstahl: string;
  assessmentYear: string;
  evaluationPrice: string;
  codeVSu: string;
  codeSachAus: string;
  factorPremiumRate: string;
  codeDeductibleVar1: string;
  discount1: string;
  discount2: string;
  codeSBBUVar1: string;
}

type PositionGroupType = {
  name: string;
  sb: number;
  sbPercent: number;
  sbMin: number;
  sbMax: number;
  mkz: string;
  maschinenart: any;

  bewertungsjahr: number;
  festwertGleitend: string;
  deckungsform: string;

  neuheitsrabatt: number;
  spindelklausel: boolean;
  stationareMaschinenMobilEingesetzt: string | null;
  stationareMaschinenMobilEingesetztPercent: number;
  mitversicherungFeuerInMASS: number;
  mitversicherungUberschwemmungInMASS: number;
  mitversicherungErdbebenInMASS: number;

  diebstahlrisiko: boolean | null;
  sbPercentRabatte: number;
  sbMinRabatte: number;
  sbMaxRabatte: number;
  ausschlussKraftKaskoRisiko: number;
  einsatzImAusland: number;

  schadenDurchVuV: number;
  einsatzUnterTage: number;
  vermietungUndVerpachtung: string | null;
  gAPDeckung: boolean;
  unterschlagungFOTGerate: boolean;
  vandalismusMTV: boolean;
}

const emptyGroupItem: PositionGroupType = {
  name: '',
  sb: 0,
  sbPercent: 0,
  sbMin: 0,
  sbMax: 0,
  mkz: '',
  maschinenart: {
    label: '',
    value: '',
  },

  bewertungsjahr: 1971,
  festwertGleitend: 'Gleitend (G)',
  deckungsform: '',

  neuheitsrabatt: 0,
  spindelklausel: false,
  stationareMaschinenMobilEingesetzt: null,
  stationareMaschinenMobilEingesetztPercent: 0,
  mitversicherungFeuerInMASS: 0,
  mitversicherungUberschwemmungInMASS: 0,
  mitversicherungErdbebenInMASS: 0,

  diebstahlrisiko: null,
  sbPercentRabatte: 0,
  sbMinRabatte: 0,
  sbMaxRabatte: 0,
  ausschlussKraftKaskoRisiko: 0,
  einsatzImAusland: 0,

  schadenDurchVuV: 0,
  einsatzUnterTage: 0,
  vermietungUndVerpachtung: null,
  gAPDeckung: false,
  unterschlagungFOTGerate: false,
  vandalismusMTV: false,
};

const emptyObject = {
};

const vertragsbezogeneZuschlageType = 2;
const positionsdatenType = 3;
const pramienSelbstbehaltsvariantenType = 4;
const erstrisikosummenType = 5;
const geltungsbereicheType = 6;

export default Vue.extend({
  name: 'individual-insurance-calc',
  mixins: [dataChangeMixin],
  components: {
    FormSwitch,
    vertragsdaten,
    vSelect,
    priceStatusIcon,
    VersicherungsortRow,
    // FormSwitch,
  },
  data() {
    return {
      name: 'individual-insurance-calc',
      key: 'INDIVIDUAL_INSURANCE',
      backUrl: 'individual-insurance',
      vertragsdatenOpen: true,
      selbstbeteiligungValues,
      maschinenartValues,
      paramsVertragsdaten: {
        sumInsured: 0,
        sum371: 0,
        paymentMethod: '',
        versicherungssteuer: 19,
        netization: '',
        factorLevel: (new Date()).getFullYear().toString(),
      },
      MACHINE_STATIONAR,
      vertragsbezogeneZuschlageItems: [
        {
          Regressverzicht: '',
          MitversicherungInnereUnruhen: false,
          calculat: false,
          additionalParams: [],
        },
      ],
      createGroupName: '',
      showMischkalkulation: false,
      collectivePositions: false,
      positionDatenGroups: [] as PositionGroupType[],
      positionsdatenItems: [
        copyObject(positionsdatenItem),
      ],
      pramienSelbstbehaltsvariantenItems: {
        tarif: {
          name: 'Tarif SB',
          ...pramienSelbstbehaltsvariantenItem,
        },
        variant1: {
          name: 'Variante 1',
          ...pramienSelbstbehaltsvariantenItem,
        },
        variant2: {
          name: 'Variante 2',
          ...pramienSelbstbehaltsvariantenItem,
        },
        variant3: {
          name: 'Variante 3',
          ...pramienSelbstbehaltsvariantenItem,
        },
      },
      versicherungsortItems: [
        {
          ...geltungsbereicheItem,
        },
      ],
      pramienSelbstbehaltsvariantenItem,
      erstrisikosummenItems: [
        {
          name: 'Aufräumungs-, Bergungs, Dekontaminations- und Ensorgungskosten',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Bewegungs- und Schutzkosten',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Luftfrachtkosten',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Erd-, Pflaster-, Maurer- und Stemmarbeiten',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Kosten für Daten',
          valueERS: 10000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Kosten für Schäden an Gebäuden',
          valueERS: 10000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
      ],
      ByVertragsbezogeneZuschlageType: vertragsbezogeneZuschlageType,
      ByPositionsdatenType: positionsdatenType,
      BypramienSelbstbehaltsvariantenType: pramienSelbstbehaltsvariantenType,
      ByerstrisikosummenType: erstrisikosummenType,
      BygeltungsbereicheType: geltungsbereicheType,
      notizen: '',
      calculation: null,
      noPrice: false,
      price: 0,
      sentData: false,
      itemsClasses: {
        tarif: '',
        variant1: 'table-variant-1',
        variant2: 'table-variant-2',
        variant3: 'table-variant-3',
      },
      refImportFileName: 'excelImport',
      loadedData: false,
      refImportPlacesFileName: 'excelImportPlaces',
      tariffPremiumCalculation: {
        tarif: {
          name: 'Tarif SB',
        },
        variant1: {
          name: 'SB Variante 1',
        },
        variant2: {
          name: 'SB Variante 2',
          priceKey: 'variant2',
        },
        variant3: {
          name: 'SB Variante 3',
        },
      },
      coverFormValues: [
        'ATWV',
        'Kaskodeckung',
        'MTV',
        MACHINE_STATIC_VALUE,
        MACHINE_FULL_COVERAGE,
      ],
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'processSavingData', 'productId', 'id', 'priceData', 'insuranceFormsApiData'],
  watch: {
    ...watchers,
    collectivePositions(value: boolean) {
      if (value) {
        this.showMischkalkulation = false;
        this.setGroupMkz();
      }
    },
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData) {
        this.fillVersicherungsortItemsFromGruppendaten(this.insuranceData.basicData.anschriftNeubau);

        if (this.firstOpenTab) {
          this.initPositionsData(this.insuranceData.basicData);
        }

        this.loadedData = true;
      }
    },
    'insuranceData.basicData': {
      handler(value) {
        this.initPositionsData(value);
      },
      deep: true,
    },
    'priceData.tarif': function (newVal, oldVal) {
      fillCalcPriceResponse(this.positionsdatenItems, newVal, 'SbTarif', 'SbTarifRabattfaktor', this.insuranceData.basicData.vertragsart, this.vertragsbezogeneZuschlageItems);
      this.paramsVertragsdaten.sum371 = getSum371(this.positionsdatenItems);
    },
    'priceData.variant1': function (newVal, oldVal) {
      fillCalcPriceResponse(this.positionsdatenItems, newVal, 'BeitragVar1', 'BeitragVarRabattfaktor1', this.insuranceData.basicData.vertragsart, this.vertragsbezogeneZuschlageItems);
      this.paramsVertragsdaten.sum371 = getSum371(this.positionsdatenItems);
    },
    'priceData.variant2': function (newVal, oldVal) {
      fillCalcPriceResponse(this.positionsdatenItems, newVal, 'BeitragVar2', 'BeitragVarRabattfaktor2', this.insuranceData.basicData.vertragsart, this.vertragsbezogeneZuschlageItems);
      this.paramsVertragsdaten.sum371 = getSum371(this.positionsdatenItems);
    },
    'priceData.variant3': function (newVal, oldVal) {
      fillCalcPriceResponse(this.positionsdatenItems, newVal, 'BeitragVar3', 'BeitragVarRabattfaktor3', this.insuranceData.basicData.vertragsart, this.vertragsbezogeneZuschlageItems);
      this.paramsVertragsdaten.sum371 = getSum371(this.positionsdatenItems);
    },
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
    showMischkalkulation(value: boolean) {
      if (value) {
        this.collectivePositions = false;
      }

      if (!value && !this.collectivePositions) {
        this.positionsdatenItems.forEach((item, i) => {
          this.positionsdatenItems[i].groupNum = null;
        });
      }
    },
  },
  methods: {
    shouldDisplayRabatt(mkz) {
      const allowedMkzValues = ['6311', '6312', '6313', '6314', '6315', '6316', '6317', '6318', '6319'];
      return allowedMkzValues.includes(mkz);
    },
    fillSumInsured() {
      this.paramsVertragsdaten.sumInsured = getSumVersicherunswert(this.positionsdatenItems);
    },
    onCurrencyFocus,
    goToBackUrl() {
      this.onSubmit(false, false);
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 1);
    },
    setGroupMkz() {
      this.positionsdatenItems.forEach((itm, i) => {
        if (
          itm.groupNum !== null &&
          this.positionDatenGroups[itm.groupNum] &&
          this.positionDatenGroups[itm.groupNum].mkz
        ) {
          this.positionsdatenItems[i].Maschinenart = this.positionDatenGroups[itm.groupNum].maschinenart;
          this.positionsdatenItems[i].MKZ = this.positionDatenGroups[itm.groupNum].mkz;
        } else if (itm.groupNum !== null) {
          if (this.positionDatenGroups[itm.groupNum]?.maschinenart && this.positionDatenGroups[itm.groupNum].maschinenart !== this.positionsdatenItems[i].Maschinenart) {
            this.positionDatenGroups[itm.groupNum].maschinenart = this.positionsdatenItems[i].Maschinenart;
          }

          if (this.positionDatenGroups[itm.groupNum]?.mkz && this.positionDatenGroups[itm.groupNum].mkz !== this.positionsdatenItems[i].MKZ) {
            this.positionDatenGroups[itm.groupNum].mkz = this.positionsdatenItems[i].MKZ;
          }
        }
      });
    },
    createOrGetGroupByName(groupName: string, additionalData: object = emptyObject): PositionGroupType {
      const group = this.positionDatenGroups.find((itm) => itm.name === groupName);

      if (group) {
        return {
          ...group,
          ...additionalData,
        };
      }

      const newGroup = {
        ...copyObject(emptyGroupItem),
        ...additionalData,
        name: groupName,
      };

      this.positionDatenGroups.push(newGroup);

      return newGroup;
    },
    findGroupNumByName(name: string): number {
      return this.positionDatenGroups.findIndex((itm) => itm.name === name);
    },
    copyDataFromFirstPositionDataItem() {
      const firstItem = this.positionsdatenItems[0];
      const issetGroup = Boolean(firstItem.groupNum !== null && this.positionDatenGroups[firstItem.groupNum]);
      const group = this.positionDatenGroups[firstItem.groupNum];

      this.positionsdatenItems.forEach((item, index) => {
        if (index > 0) {
          this.positionsdatenItems[index].BU = firstItem.BU;
          this.positionsdatenItems[index].Deckungsform = firstItem.Deckungsform;

          this.setDiscountsPositionsDataItem(index, (group || firstItem), issetGroup);
        }
      });
      this.positionDatenGroups.forEach((item, index) => {
        if (index > 0) {
          this.setDiscountsPositionsGroupDataItem(index, (group || firstItem), issetGroup);
        }
      });
    },
    clearDataFromPositionDataItems() {
      this.positionsdatenItems.forEach((item, index) => {
        this.positionsdatenItems[index].BU = '';
        this.positionsdatenItems[index].Deckungsform = '';

        this.setDiscountsPositionsDataItem(index, null);
      });
      this.positionDatenGroups.forEach((item, index) => {
        this.setDiscountsPositionsGroupDataItem(index, null);
      });
    },
    setDiscountsPositionsDataItem(index: number, firstItem: any|null, fromGroup = false) {
      this.positionsdatenItems[index].Neuheitsrabatt = fromGroup ? firstItem?.neuheitsrabatt : firstItem?.Neuheitsrabatt;
      this.positionsdatenItems[index].Spindelklausel = fromGroup ? firstItem?.spindelklausel : firstItem?.Spindelklausel;
      this.positionsdatenItems[index].StationareMaschinenMobilEingesetzt = fromGroup ? firstItem?.stationareMaschinenMobilEingesetzt : firstItem?.StationareMaschinenMobilEingesetzt;
      this.positionsdatenItems[index].StationareMaschinenMobilEingesetztPercent = fromGroup ? firstItem?.stationareMaschinenMobilEingesetztPercent : firstItem?.StationareMaschinenMobilEingesetztPercent;
      this.positionsdatenItems[index].MitversicherungFeuerInMASS = fromGroup ? firstItem?.mitversicherungFeuerInMASS : firstItem?.MitversicherungFeuerInMASS;
      this.positionsdatenItems[index].MitversicherungUberschwemmungInMASS = fromGroup ? firstItem?.mitversicherungUberschwemmungInMASS : firstItem?.MitversicherungUberschwemmungInMASS;
      this.positionsdatenItems[index].MitversicherungErdbebenInMASS = fromGroup ? firstItem?.mitversicherungErdbebenInMASS : firstItem?.MitversicherungErdbebenInMASS;

      this.positionsdatenItems[index].Diebstahlrisiko = fromGroup ? firstItem?.diebstahlrisiko : firstItem?.Diebstahlrisiko;
      this.positionsdatenItems[index].SbPercent = fromGroup ? firstItem?.sbPercentRabatte : firstItem?.SbPercent;
      this.positionsdatenItems[index].SbMin = fromGroup ? firstItem?.sbMinRabatte : firstItem?.SbMin;
      this.positionsdatenItems[index].SbMax = fromGroup ? firstItem?.sbMaxRabatte : firstItem?.SbMax;
      this.positionsdatenItems[index].AusschlussKraftKaskoRisiko = fromGroup ? firstItem?.ausschlussKraftKaskoRisiko : firstItem?.AusschlussKraftKaskoRisiko;
      this.positionsdatenItems[index].EinsatzImAusland = fromGroup ? firstItem?.einsatzImAusland : firstItem?.EinsatzImAusland;

      this.positionsdatenItems[index].SchadenDurchVuV = fromGroup ? firstItem?.schadenDurchVuV : firstItem?.SchadenDurchVuV;
      this.positionsdatenItems[index].EinsatzUnterTage = fromGroup ? firstItem?.einsatzUnterTage : firstItem?.EinsatzUnterTage;
      this.positionsdatenItems[index].VermietungUndVerpachtung = fromGroup ? firstItem?.vermietungUndVerpachtung : firstItem?.VermietungUndVerpachtung;
      this.positionsdatenItems[index].GAPDeckung = fromGroup ? firstItem?.gAPDeckung : firstItem?.GAPDeckung;
      this.positionsdatenItems[index].UnterschlagungFOTGerate = fromGroup ? firstItem?.unterschlagungFOTGerate : firstItem?.UnterschlagungFOTGerate;
      this.positionsdatenItems[index].VandalismusMTV = fromGroup ? firstItem?.vandalismusMTV : firstItem?.VandalismusMTV;
      this.positionsdatenItems[index].Deckungsform = fromGroup ? firstItem?.deckungsform : firstItem?.Deckungsform;
    },
    setDiscountsPositionsGroupDataItem(index: number, firstItem: any|null, fromGroup = false) {
      this.positionDatenGroups[index].neuheitsrabatt = fromGroup ? firstItem?.neuheitsrabatt : firstItem?.Neuheitsrabatt;
      this.positionDatenGroups[index].spindelklausel = fromGroup ? firstItem?.spindelklausel : firstItem?.Spindelklausel;
      this.positionDatenGroups[index].stationareMaschinenMobilEingesetzt = fromGroup ? firstItem?.stationareMaschinenMobilEingesetzt : firstItem?.StationareMaschinenMobilEingesetzt;
      this.positionDatenGroups[index].stationareMaschinenMobilEingesetztPercent = fromGroup ? firstItem?.stationareMaschinenMobilEingesetztPercent : firstItem?.StationareMaschinenMobilEingesetztPercent;
      this.positionDatenGroups[index].mitversicherungFeuerInMASS = fromGroup ? firstItem?.mitversicherungFeuerInMASS : firstItem?.MitversicherungFeuerInMASS;
      this.positionDatenGroups[index].mitversicherungUberschwemmungInMASS = fromGroup ? firstItem?.mitversicherungUberschwemmungInMASS : firstItem?.MitversicherungUberschwemmungInMASS;
      this.positionDatenGroups[index].mitversicherungErdbebenInMASS = fromGroup ? firstItem?.mitversicherungErdbebenInMASS : firstItem?.MitversicherungErdbebenInMASS;

      this.positionDatenGroups[index].diebstahlrisiko = fromGroup ? firstItem?.diebstahlrisiko : firstItem?.Diebstahlrisiko;
      this.positionDatenGroups[index].sbPercentRabatte = fromGroup ? firstItem?.sbPercentRabatte : firstItem?.SbPercent;
      this.positionDatenGroups[index].sbMinRabatte = fromGroup ? firstItem?.sbMinRabatte : firstItem?.SbMin;
      this.positionDatenGroups[index].sbMaxRabatte = fromGroup ? firstItem?.sbMaxRabatte : firstItem?.SbMax;
      this.positionDatenGroups[index].ausschlussKraftKaskoRisiko = fromGroup ? firstItem?.ausschlussKraftKaskoRisiko : firstItem?.AusschlussKraftKaskoRisiko;
      this.positionDatenGroups[index].einsatzImAusland = fromGroup ? firstItem?.einsatzImAusland : firstItem?.EinsatzImAusland;

      this.positionDatenGroups[index].schadenDurchVuV = fromGroup ? firstItem?.schadenDurchVuV : firstItem?.SchadenDurchVuV;
      this.positionDatenGroups[index].einsatzUnterTage = fromGroup ? firstItem?.einsatzUnterTage : firstItem?.EinsatzUnterTage;
      this.positionDatenGroups[index].vermietungUndVerpachtung = fromGroup ? firstItem?.vermietungUndVerpachtung : firstItem?.VermietungUndVerpachtung;
      this.positionDatenGroups[index].gAPDeckung = fromGroup ? firstItem?.gAPDeckung : firstItem?.GAPDeckung;
      this.positionDatenGroups[index].unterschlagungFOTGerate = fromGroup ? firstItem?.unterschlagungFOTGerate : firstItem?.UnterschlagungFOTGerate;
      this.positionDatenGroups[index].vandalismusMTV = fromGroup ? firstItem?.vandalismusMTV : firstItem?.VandalismusMTV;
      this.positionDatenGroups[index].deckungsform = fromGroup ? firstItem?.deckungsform : firstItem?.Deckungsform;
    },
    chooseImportPlacesFile() {
      this.excelImportPlacesRef.click();
    },
    initPositionsData(basicFormData: any) {
      this.positionsdatenItems.forEach((item, index) => {
        if (!this.sentData) {
          this.positionsdatenItems[index].Deckungsform = this.getDeckungsformValue(basicFormData);
          this.positionsdatenItems[index].Diebstahlrisiko = this.getDiebstahlrisikoValue(basicFormData);
        }
        this.setIndividualSurchargeValue100(index);
      });
    },
    getDeckungsformValue(basicFormData: any, coverageForm: string|null = null): string {
      if (coverageForm) {
        return coverageForm;
      }

      return basicFormData?.vertragsart === MACHINE_FAHRBAR ? MACHINE_FULL_COVERAGE : MACHINE_STATIC_VALUE;
    },
    getDiebstahlrisikoValue(basicFormData: any): boolean {
      return basicFormData?.vertragsart === MACHINE_FAHRBAR;
    },
    uploadPlacesFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-places-excel`, 'import', file).then((response: any) => {
          this.importPlacesFromExcel(response.data);
          this.excelImportPlacesRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    importPlacesFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.versicherungsortItems.length === 1 &&
        !this.versicherungsortItems[0].strasse &&
        !this.versicherungsortItems[0].plz &&
        !this.versicherungsortItems[0].ort &&
        !this.versicherungsortItems[0].land;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.versicherungsortItems[0] : copyObject(geltungsbereicheItem);

        newItem.strasse = item.street;
        newItem.plz = item.postCode;
        newItem.ort = item.place;
        newItem.land = item.land;

        if (!fillFirst) {
          this.versicherungsortItems.push(newItem);
        }
      });
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.spreadsheetData) {
        recursiveFillData(this.erstrisikosummenItems, this.insuranceFormsApiData.spreadsheetData.erstrisikosummenItems);
        recursiveFillData(this.vertragsbezogeneZuschlageItems, this.insuranceFormsApiData.spreadsheetData.vertragsbezogeneZuschlageItems);
        recursiveFillData(this.paramsVertragsdaten, this.insuranceFormsApiData.spreadsheetData.paramsVertragsdaten);
        for (let i = 1; i < this.insuranceFormsApiData.spreadsheetData.positionsdatenItems.length; i += 1) {
          duplicateDataItems(positionsdatenItem, this.positionsdatenItems, false);
        }
        recursiveFillData(this.positionsdatenItems, this.insuranceFormsApiData.spreadsheetData.positionsdatenItems);

        for (let i = 1; i < this.insuranceFormsApiData.spreadsheetData.versicherungsortItems.length; i += 1) {
          duplicateDataItems(geltungsbereicheItem, this.versicherungsortItems, false);
        }
        recursiveFillData(this.versicherungsortItems, this.insuranceFormsApiData.spreadsheetData.versicherungsortItems);
      }
    },
    addPostionsDataGroup() {
      if (this.createGroupName) {
        const group = copyObject(emptyGroupItem);

        group.name = this.createGroupName;

        this.positionDatenGroups.push(group);
        this.createGroupName = '';
      }
    },
    groupBy(items: any[], key: string): any {
      return items.reduce((accumulator, currentValue, index) => {
        const accumulatorElement = accumulator;

        if (!accumulatorElement[currentValue[key]]) {
          accumulatorElement[currentValue[key]] = {
          };
        }

        accumulatorElement[currentValue[key]][index] = currentValue;

        return accumulatorElement;
      },
      {
      });
    },
    uploadFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-excel`, 'import', file).then((response: any) => {
          this.importPositionsdatenFromExcel(response.data);
          this.excelImportRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    changeGroup(groupNum, positionDataIndex) {
      if (groupNum !== null && this.positionDatenGroups[groupNum]) {
        if (!this.positionDatenGroups[groupNum].sb) {
          this.positionDatenGroups[groupNum].sb = this.positionsdatenItems[positionDataIndex].SB;
        }
        if (!this.positionDatenGroups[groupNum].sbPercent) {
          this.positionDatenGroups[groupNum].sbPercent = this.positionsdatenItems[positionDataIndex].SBpercent;
        }
        if (!this.positionDatenGroups[groupNum].sbMin) {
          this.positionDatenGroups[groupNum].sbMin = this.positionsdatenItems[positionDataIndex].SBmin;
        }
        if (!this.positionDatenGroups[groupNum].sbMax) {
          this.positionDatenGroups[groupNum].sbMax = this.positionsdatenItems[positionDataIndex].SBmax;
        }
        if (!this.positionDatenGroups[groupNum].festwertGleitend) {
          this.positionDatenGroups[groupNum].festwertGleitend = this.positionsdatenItems[positionDataIndex].FestwertGleitend;
        }
        if (!this.positionDatenGroups[groupNum].deckungsform) {
          this.positionDatenGroups[groupNum].deckungsform = this.positionsdatenItems[positionDataIndex].Deckungsform;
        }
        if (!this.positionDatenGroups[groupNum].neuheitsrabatt) {
          this.positionDatenGroups[groupNum].neuheitsrabatt = this.positionsdatenItems[positionDataIndex].Neuheitsrabatt;
        }
        if (!this.positionDatenGroups[groupNum].spindelklausel) {
          this.positionDatenGroups[groupNum].spindelklausel = this.positionsdatenItems[positionDataIndex].Spindelklausel;
        }
        if (!this.positionDatenGroups[groupNum].stationareMaschinenMobilEingesetzt) {
          this.positionDatenGroups[groupNum].stationareMaschinenMobilEingesetzt = this.positionsdatenItems[positionDataIndex].StationareMaschinenMobilEingesetztPercent;
        }
        if (!this.positionDatenGroups[groupNum].stationareMaschinenMobilEingesetztPercent) {
          this.positionDatenGroups[groupNum].stationareMaschinenMobilEingesetztPercent = this.positionsdatenItems[positionDataIndex].StationareMaschinenMobilEingesetztPercent;
        }
        if (!this.positionDatenGroups[groupNum].mitversicherungFeuerInMASS) {
          this.positionDatenGroups[groupNum].mitversicherungFeuerInMASS = this.positionsdatenItems[positionDataIndex].MitversicherungFeuerInMASS;
        }
        if (!this.positionDatenGroups[groupNum].mitversicherungErdbebenInMASS) {
          this.positionDatenGroups[groupNum].mitversicherungErdbebenInMASS = this.positionsdatenItems[positionDataIndex].MitversicherungUberschwemmungInMASS;
        }
        if (!this.positionDatenGroups[groupNum].diebstahlrisiko) {
          this.positionDatenGroups[groupNum].diebstahlrisiko = this.positionsdatenItems[positionDataIndex].Diebstahlrisiko;
        }
        if (!this.positionDatenGroups[groupNum].sbPercentRabatte) {
          this.positionDatenGroups[groupNum].sbPercentRabatte = this.positionsdatenItems[positionDataIndex].SbPercent;
        }
        if (!this.positionDatenGroups[groupNum].sbMinRabatte) {
          this.positionDatenGroups[groupNum].sbMinRabatte = this.positionsdatenItems[positionDataIndex].SbMin;
        }
        if (!this.positionDatenGroups[groupNum].sbMaxRabatte) {
          this.positionDatenGroups[groupNum].sbMaxRabatte = this.positionsdatenItems[positionDataIndex].SbMax;
        }
        if (!this.positionDatenGroups[groupNum].ausschlussKraftKaskoRisiko) {
          this.positionDatenGroups[groupNum].ausschlussKraftKaskoRisiko = this.positionsdatenItems[positionDataIndex].AusschlussKraftKaskoRisiko;
        }
        if (!this.positionDatenGroups[groupNum].einsatzImAusland) {
          this.positionDatenGroups[groupNum].einsatzImAusland = this.positionsdatenItems[positionDataIndex].EinsatzImAusland;
        }
        if (!this.positionDatenGroups[groupNum].schadenDurchVuV) {
          this.positionDatenGroups[groupNum].schadenDurchVuV = this.positionsdatenItems[positionDataIndex].SchadenDurchVuV;
        }
        if (!this.positionDatenGroups[groupNum].einsatzUnterTage) {
          this.positionDatenGroups[groupNum].einsatzUnterTage = this.positionsdatenItems[positionDataIndex].EinsatzUnterTage;
        }
        if (!this.positionDatenGroups[groupNum].vermietungUndVerpachtung) {
          this.positionDatenGroups[groupNum].vermietungUndVerpachtung = this.positionsdatenItems[positionDataIndex].VermietungUndVerpachtung;
        }
        if (!this.positionDatenGroups[groupNum].gAPDeckung) {
          this.positionDatenGroups[groupNum].gAPDeckung = this.positionsdatenItems[positionDataIndex].GAPDeckung;
        }
        if (!this.positionDatenGroups[groupNum].unterschlagungFOTGerate) {
          this.positionDatenGroups[groupNum].unterschlagungFOTGerate = this.positionsdatenItems[positionDataIndex].UnterschlagungFOTGerate;
        }
        if (!this.positionDatenGroups[groupNum].vandalismusMTV) {
          this.positionDatenGroups[groupNum].vandalismusMTV = this.positionsdatenItems[positionDataIndex].VandalismusMTV;
        }
        if (
          (
            this.collectivePositions ||
            (
              !this.positionDatenGroups[groupNum].mkz &&
              !this.positionDatenGroups[groupNum].maschinenart?.value
            )
          ) &&
          this.positionsdatenItems[positionDataIndex].MKZ &&
          this.positionsdatenItems[positionDataIndex].Maschinenart
        ) {
          this.positionDatenGroups[groupNum].mkz = this.positionsdatenItems[positionDataIndex].MKZ;
          this.positionDatenGroups[groupNum].maschinenart = this.positionsdatenItems[positionDataIndex].Maschinenart;
        }

        this.setIndividualSurchargeValue100ByGroup(groupNum);
      }

      if (this.collectivePositions) {
        this.setGroupMkz();
      }
    },
    importPositionsdatenFromExcel(dataItems: any[]) {
      dataItems.forEach((item, i) => {
        let currentItem;
        if (this.positionsdatenItems[i]) {
          currentItem = this.positionsdatenItems[i];
        } else {
          currentItem = copyObject(positionsdatenItem);
          currentItem.keysForXML = createXMLKeys();
          this.positionsdatenItems.push(currentItem);
        }

        const { groupName } = item;

        let groupNum = -1;

        if (groupName) {
          const group = this.createOrGetGroupByName(
            groupName,
            {
              sb: +item.sb,
              bewertungsjahr: item.assessmentYear,
              deckungsform: this.getDeckungsformValue(this.insuranceData?.basicData, item.coverageForm),
              diebstahlrisiko: this.getDiebstahlrisikoValue(this.insuranceData?.basicData),
              gAPDeckung: item.gapCover === 'Ja' || item.gapCover === 'ja',
              mkz: item.mkz,
            },
          );

          groupNum = this.findGroupNumByName(group.name);

          if (groupNum !== -1 && !this.showMischkalkulation && !this.collectivePositions) {
            if (this.isMachineStationar) {
              this.showMischkalkulation = true;
            }

            if (this.isMachineFahrbar) {
              this.collectivePositions = true;
            }
          }
        }

        currentItem.Beschreibung = item.description;
        currentItem.MKZ = item.mkz;
        currentItem.Versicherunswert = item.sumInsured;
        currentItem.Bewertungsjahr = item.assessmentYear;
        currentItem.Baujahr = item.constructionYear;
        currentItem.Hersteller = item.manufacturer;
        currentItem.Seriennummer = item.serialNumber;
        currentItem.Typ = item.type;
        currentItem.SB = +item.sb;
        currentItem.Deckungsform = this.getDeckungsformValue(this.insuranceData?.basicData, item.coverageForm);
        currentItem.Diebstahlrisiko = this.getDiebstahlrisikoValue(this.insuranceData?.basicData);
        currentItem.GAPDeckung = item.gapCover === 'Ja' || item.gapCover === 'ja';
        currentItem.groupNum = groupNum !== -1 ? groupNum : null;
      });
    },
    chooseImportFile() {
      this.excelImportRef.click();
    },
    fillVersicherungsortItemsFromGruppendaten(basicData: any) {
      if (!this.versicherungsortItems[0].strasse) {
        this.versicherungsortItems[0].strasse = basicData.Strabe;
      }

      if (!this.versicherungsortItems[0].plz) {
        this.versicherungsortItems[0].plz = basicData.PLZ;
      }

      if (!this.versicherungsortItems[0].ort) {
        this.versicherungsortItems[0].ort = basicData.Ort;
      }

      if (!this.versicherungsortItems[0].land) {
        this.versicherungsortItems[0].land = basicData.Land;
      }
      if (!this.versicherungsortItems[0].hausnummer) {
        this.versicherungsortItems[0].hausnummer = basicData.Nr;
      }

      if (!this.versicherungsortItems[0].zuers) {
        this.versicherungsortItems[0].zuers = basicData.Zurs;
      }

      if (!this.versicherungsortItems[0].starkregenzone) {
        this.versicherungsortItems[0].starkregenzone = basicData.Starkregenzone;
      }

      if (!this.versicherungsortItems[0].naturgefahren2) {
        this.versicherungsortItems[0].naturgefahren2 = basicData.Naturgefahrenklasse2;
      }

      if (!this.versicherungsortItems[0].naturgefahren3) {
        this.versicherungsortItems[0].naturgefahren3 = basicData.Naturgefahrenklasse3;
      }
    },
    deleteErstrisikosummenItem(key) {
      this.erstrisikosummenItems.splice(key, 1);
    },
    deleteAdditionalParams(i: number, j: number) {
      this.vertragsbezogeneZuschlageItems[i].additionalParams.splice(j, 1);
    },
    changeMaschinenart(index: number) {
      this.positionsdatenItems[index].MKZ = this.getMkzNumberBychangeMaschinenart(this.positionsdatenItems[index].Maschinenart.label);
      const machineType = this.getMaschinenartBychangeMaschinenart(this.positionsdatenItems[index].Maschinenart.label);
      this.setDataByMachineType(machineType, index);
      const sbData = this.getSelbstbeteiligungByCode(this.positionsdatenItems[index].MKZ);
      this.setDataByMachineType(sbData, index);
      this.setIndividualSurchargeValue100(index);
    },
    changeMaschinenartCode(index: number) {
      this.positionsdatenItems[index].Maschinenart = this.getMachineTypeByCode(this.positionsdatenItems[index].MKZ);
      const machineType = this.getSelbstbeteiligungByCode(this.positionsdatenItems[index].MKZ);
      this.setDataByMachineType(machineType, index);
      this.setIndividualSurchargeValue100(index);
    },
    setDataByMachineType(machineType: any, index: number) {
      if (machineType.selbstbeteiligung !== null) {
        this.positionsdatenItems[index].SB = Number(machineType.selbstbeteiligung);
        this.positionsdatenItems[index].hideSB = Number(machineType.selbstbeteiligung);
        this.pramienSelbstbehaltsvariantenItems.variant1.SB = Number(machineType.selbstbeteiligung);
        this.pramienSelbstbehaltsvariantenItems.variant2.SB = Number(machineType.selbstbeteiligung);
      }
      if (machineType.percent !== null) {
        this.positionsdatenItems[index].SBpercent = Number(machineType.percent);
        this.pramienSelbstbehaltsvariantenItems.variant1.SBpercent = Number(machineType.percent);
        this.pramienSelbstbehaltsvariantenItems.variant2.SBpercent = Number(machineType.percent);
      }
      if (machineType.sbMin !== null) {
        this.positionsdatenItems[index].SBmin = Number(machineType.sbMin);
        this.pramienSelbstbehaltsvariantenItems.variant1.SBmin = Number(machineType.sbMin);
        this.pramienSelbstbehaltsvariantenItems.variant2.SBmin = Number(machineType.sbMin);
      }
    },
    setGroupDataByMachineType(machineType: any, groupNum: number) {
      if (machineType.selbstbeteiligung !== null) {
        this.positionDatenGroups[groupNum].sb = Number(machineType.selbstbeteiligung);
        this.pramienSelbstbehaltsvariantenItems.variant1.SB = Number(machineType.selbstbeteiligung);
        this.pramienSelbstbehaltsvariantenItems.variant2.SB = Number(machineType.selbstbeteiligung);
      }
      if (machineType.percent !== null) {
        this.positionDatenGroups[groupNum].sbPercent = Number(machineType.percent);
        this.pramienSelbstbehaltsvariantenItems.variant1.SBpercent = Number(machineType.percent);
        this.pramienSelbstbehaltsvariantenItems.variant2.SBpercent = Number(machineType.percent);
      }
      if (machineType.sbMin !== null) {
        this.positionDatenGroups[groupNum].sbMin = Number(machineType.sbMin);
        this.pramienSelbstbehaltsvariantenItems.variant1.SBmin = Number(machineType.sbMin);
        this.pramienSelbstbehaltsvariantenItems.variant2.SBmin = Number(machineType.sbMin);
      }
    },
    changeGroupMaschinenart(groupNum: number) {
      this.positionDatenGroups[groupNum].mkz = this.getMkzNumberBychangeMaschinenart(this.positionDatenGroups[groupNum].maschinenart.label);
      const machineType = this.getSelbstbeteiligungByCode(this.positionDatenGroups[groupNum].mkz);
      this.setGroupDataByMachineType(machineType, groupNum);
      this.setIndividualSurchargeValue100ByGroup(groupNum);
      this.setGroupMkz();
    },
    changeGroupMaschinenartCode(groupNum: number) {
      this.positionDatenGroups[groupNum].maschinenart = this.getMachineTypeByCode(this.positionDatenGroups[groupNum].mkz);
      const machineType = this.getSelbstbeteiligungByCode(this.positionDatenGroups[groupNum].mkz);
      this.setGroupDataByMachineType(machineType, groupNum);
      this.setIndividualSurchargeValue100ByGroup(groupNum);
      this.setGroupMkz();
    },
    getMachineTypeByCode(code: string) {
      return this.maschinenartValues.find((itm) => itm.value.endsWith(`-${code}`)) || {
        label: '',
        value: '',
      };
    },
    getSelbstbeteiligungByCode(code: string) {
      return this.maschinenartValuesForSB.find((itm) => itm.value.endsWith(code)) || {
        selbstbeteiligung: null,
        percent: null,
        sbMin: null,
      };
    },
    getMkzNumberBychangeMaschinenart(machineType: string) {
      const foundItem = this.maschinenartValues.find((itm) => itm.label === machineType);

      if (foundItem) {
        const codeData = foundItem.value.split('-');
        return codeData[1] || '';
      }
      return '';
    },
    getMaschinenartBychangeMaschinenart(machineType: string) {
      const foundItem = this.maschinenartValuesForSB.find((itm) => itm.label === machineType);

      if (foundItem) {
        return foundItem;
      }

      return {
        selbstbeteiligung: null,
        percent: null,
        sbMin: null,
      };
    },
    deleteRows(type: number) {
      let items;
      switch (type) {
        case this.ByPositionsdatenType:
          unselectFirstElement(this.positionsdatenItems);

          items = this.positionsdatenItems.filter((item) => !item.isSelected);
          this.positionsdatenItems = items;
          break;
        case this.BygeltungsbereicheType:
          unselectFirstElement(this.versicherungsortItems);

          items = this.versicherungsortItems.filter((item) => !item.isSelected);
          this.versicherungsortItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    addAdditionalParam(type: number) {
      const item = additionalParam;
      switch (type) {
        case this.ByVertragsbezogeneZuschlageType:
          // this.vertragsbezogeneRabatteItems[0].additionalParams.push(item);
          this.vertragsbezogeneZuschlageItems.forEach((element: any, index) => {
            // item.value = index;
            element.additionalParams.push({
              ...item,
            });
          });
          break;
        default:
          console.log('Wrong type');
      }
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.ByVertragsbezogeneZuschlageType:
          duplicateDataItemsWithoutSelect(vertragsbezogeneZuschlageItem, this.vertragsbezogeneZuschlageItems);
          break;
        case this.ByPositionsdatenType:
          duplicateIndividualPositionsdatenItems(positionsdatenItem, this.positionsdatenItems, fillData);
          break;
        case this.ByerstrisikosummenType:
          duplicateDataItemsWithoutSelect(erstrisikosummenItem, this.erstrisikosummenItems);
          break;
        case this.BygeltungsbereicheType:
          duplicateDataItems(geltungsbereicheItem, this.versicherungsortItems, fillData);
          break;
        default:
          console.log('Wrong type');
      }
    },
    paramsVertragsdatenChange(params: IVertragsfaten) {
      this.paramsVertragsdaten.sumInsured = params.sumInsured;
      this.paramsVertragsdaten.sum371 = params.sum371;
      this.paramsVertragsdaten.paymentMethod = params.paymentMethod;
      this.paramsVertragsdaten.versicherungssteuer = params.versicherungssteuer;
      this.paramsVertragsdaten.netization = params.netization;
      this.paramsVertragsdaten.factorLevel = params.factorLevel;
    },
    setValues(insuranceData: any) {
      const calculationData = insuranceData.spreadsheetData;
      this.paramsVertragsdaten = calculationData.paramsVertragsdaten;
      // this.vertragsbezogeneRabatteItems = calculationData.vertragsbezogeneRabatteItems;
      this.vertragsbezogeneZuschlageItems = calculationData.vertragsbezogeneZuschlageItems;
      this.positionsdatenItems = calculationData.positionsdatenItems;
      this.positionDatenGroups = calculationData.positionDatenGroups || [];
      this.pramienSelbstbehaltsvariantenItem = calculationData.pramienSelbstbehaltsvariantenItem;
      this.pramienSelbstbehaltsvariantenItems = calculationData.pramienSelbstbehaltsvariantenItems;
      this.erstrisikosummenItems = calculationData.erstrisikosummenItems;
      this.notizen = calculationData.notizen;
      this.versicherungsortItems = calculationData.versicherungsortItems;
      this.showMischkalkulation = calculationData?.showMischkalkulation || false;
      this.collectivePositions = calculationData?.collectivePositions || false;
      this.sentData = calculationData?.sentData || false;
    },
    setPrice() {
      const machinery: PriceItem[] = [];
      // const priceApiData = [];
      this.positionsdatenItems.forEach((element: any, index) => {
        const machineryItem = {
          machineCodeNumber: element.MKZ,
          premiumFactor: '',
          sumFactor: '',
          codeSFPF: 'L001C0001',
          codeABMGDiebstahl: 'L002C0001',
          assessmentYear: element.Bewertungsjahr,
          evaluationPrice: element.Versicherunswert,
          codeVSu: 'L101C0006',
          codeSachAus: 'L102C0001',
          factorPremiumRate: element.Pramiensatz,
          codeDeductibleVar1: element.SB,
          discount1: '',
          discount2: '',
          codeSBBUVar1: '',
        };
        machinery.push(machineryItem);
      });
      const priceData = {
        insuranceKey: this.key,
        // productId: '51c9aa29-3ff3-4e2b-8f73-95bfe638adec',
        codeProductId: 'L000C0001',
        codeSubProductId: 'L100C0001',
        machinery,
        awardOverview: {
          codePaymentMethod: this.paramsVertragsdaten.paymentMethod,
          bonusYear: '2022',
          commission: '',
          insuranceTax: '0',
        },
      };
      this.$emit('price-card-data', priceData);
    },
    onSubmit(nextPrice = false, changeTabId = true) {
      this.sentData = true;

      const data = {
        paramsVertragsdaten: this.paramsVertragsdaten,
        // vertragsbezogeneRabatteItems: this.vertragsbezogeneRabatteItems,
        vertragsbezogeneZuschlageItems: this.vertragsbezogeneZuschlageItems,
        positionsdatenItems: this.positionsdatenItems,
        positionDatenGroups: this.positionDatenGroups,
        pramienSelbstbehaltsvariantenItem: this.pramienSelbstbehaltsvariantenItem,
        pramienSelbstbehaltsvariantenItems: this.pramienSelbstbehaltsvariantenItems,
        erstrisikosummenItems: this.erstrisikosummenItems,
        versicherungsortItems: this.versicherungsortItems,
        notizen: this.notizen,
        showMischkalkulation: this.showMischkalkulation,
        collectivePositions: this.collectivePositions,
        sentData: this.sentData,
      };
      this.$emit('spreadsheet-data-change', data, this.key, nextPrice, changeTabId);
      // this.setPrice();
    },
    queueGetPriceRequest(variantPrice: string) {
      this.$emit('add-to-queue', 'getPriceRequestUseId', variantPrice);
      this.onSubmit(false, false);
    },
    queueGetPrice(variantPrice: string) {
      this.onSubmit(false, false);
      this.$emit('add-to-queue', 'getPriceUseId', variantPrice);
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Maschine-Tarif.pdf');
    },
    savePdf() {
      this.sentData = true;
      saveZusammenfassungPDF.call(this, this.id, 'saveTarifPDF', this.onSubmit);
    },
    enableStationareMaschinenMobilEingesetztPercent(item: any): boolean {
      return (item.StationareMaschinenMobilEingesetzt === 'Einsatzort außerhalb BRD');
    },
    enableStationareMaschinenMobilEingesetztPercentGroup(item: any): boolean {
      return (this.positionDatenGroups[item.groupNum].stationareMaschinenMobilEingesetzt === 'Einsatzort außerhalb BRD');
    },
    changeStationareMaschinenMobilEingesetzt(item: any) {
      if (this.enableStationareMaschinenMobilEingesetztPercent(item)) {
        // eslint-disable-next-line no-param-reassign
        item.StationareMaschinenMobilEingesetztPercent = 0;
        return;
      }

      const factors = {
        'bei wechselndem Einsatzort': 5,
        'ohne wechselnden Einsatzort': 2.5,
        '': 0,
      };

      const StationareMaschinenMobilEingesetztPercent = factors[item.StationareMaschinenMobilEingesetzt] || 0;

      // eslint-disable-next-line no-param-reassign
      item.StationareMaschinenMobilEingesetztPercent = (((Number(item.Pramiensatz) + Number(StationareMaschinenMobilEingesetztPercent)) / Number(item.Pramiensatz)) - 1) * 100;
    },
    changeStationareMaschinenMobilEingesetztGroup(item: any) {
      if (item.groupNum !== null && this.enableStationareMaschinenMobilEingesetztPercentGroup(item)) {
        // eslint-disable-next-line no-param-reassign
        this.positionDatenGroups[item.groupNum].stationareMaschinenMobilEingesetzt = '';
        return;
      }

      const factors = {
        'bei wechselndem Einsatzort': 5,
        'ohne wechselnden Einsatzort': 2.5,
        '': 0,
      };

      if (item.groupNum !== null) {
        const k = this.positionDatenGroups[item.groupNum].stationareMaschinenMobilEingesetzt;

        if (k !== null) {
          const StationareMaschinenMobilEingesetztPercent = factors[k] || 0;

          this.positionDatenGroups[item.groupNum].stationareMaschinenMobilEingesetztPercent = (((Number(item.Pramiensatz) + Number(StationareMaschinenMobilEingesetztPercent)) / Number(item.Pramiensatz)) - 1) * 100;
        }
      }
    },
    downloadInsurancePlacesTemplate() {
      apiBackend.downloadInsurancePlaces().then((response) => {
        downloadFile(response.data, 'Einlesen von Versicherungsorten.xlsx');
      });
    },
    exportPlacesData() {
      const data = this.versicherungsortItems.map((itm) => ({
        street: itm.strasse,
        code: itm.plz,
        city: itm.ort,
        land: itm.land,
      }));

      apiBackend.exportPlacesData(data).then((response) => {
        downloadFile(response.data, 'Versicherungsort-Maschine.xlsx');
      });
    },
    downloadPositionsDataTemplate() {
      apiBackend.downloadPositionsData(INDIVIDUAL_INSURANCE_KEY).then((response) => {
        downloadFile(response.data, 'Einlesen von Gerätelisten.xlsx');
      });
    },
    setIndividualSurchargeValue100(index: number) {
      if (
        !this.positionsdatenItems[index].individualSurcharge &&
        this.positionsdatenItems[index].MKZ === '6320' &&
        this.positionsdatenItems[index].Deckungsform === 'MTV' &&
        this.isMachineFahrbar
      ) {
        this.positionsdatenItems[index].individualSurcharge = 100;
        this.positionsdatenItems[index].showIndividualSurchargeMessage = true;
      }
    },
    setIndividualSurchargeValue100ByGroup(groupNum: number) {
      const groupItem = this.positionDatenGroups[groupNum];

      this.positionsdatenItems.forEach((positionDataItem, index) => {
        if (
          positionDataItem.groupNum !== null &&
          +positionDataItem.groupNum === +groupNum &&
          !positionDataItem.individualSurcharge &&
          groupItem?.mkz === '6320' &&
          groupItem?.deckungsform === 'MTV' &&
          this.isMachineFahrbar
        ) {
          this.positionsdatenItems[index].individualSurcharge = 100;
          this.positionsdatenItems[index].showIndividualSurchargeMessage = true;
        }
      });
    },
  },
  computed: {
    vertragsart(): string {
      return this.insuranceData?.basicData?.vertragsart || '';
    },
    isMachineStationar(): boolean {
      return this.vertragsart === MACHINE_STATIONAR;
    },
    isMachineFahrbar(): boolean {
      return this.vertragsart === MACHINE_FAHRBAR;
    },
    countVersicherungsortItems(): number {
      return this.versicherungsortItems.length;
    },
    maschinenartValuesForSB(): any {
      if (this.isMachineFahrbar) {
        return maschinenartValuesFahrbar;
      }
      if (this.isMachineStationar) {
        return maschinenartValuesStationar;
      }
      return maschinenartValuesStationar;
    },
    positionDataGroupedItems(): any {
      return this.groupBy(this.positionsdatenItems, 'groupNum');
    },
    postionsDatenTitles(): string[] {
      return this.positionsdatenItems.map((item) => {
        const machineTypeTitle = item.Maschinenart?.label || 'Maschinenart';
        const descriptionTitle = item.Beschreibung || 'Beschreibung';

        return `${machineTypeTitle} - ${descriptionTitle}`;
      });
    },
    showPlusBtn(): boolean {
      return (this.erstrisikosummenItems.length < 12);
    },
    showMinusBtn(): boolean {
      return (this.erstrisikosummenItems.length > 8);
    },
    firstOpenTab(): boolean {
      return (this.insuranceData === null || this.insuranceData.spreadsheetData === null) && (!this.loadedData);
    },
    excelImportRef(): any {
      return this.$refs[this.refImportFileName];
    },
    excelImportPlacesRef(): any {
      return this.$refs[this.refImportPlacesFileName];
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (!this.firstOpenTab) {
      this.setValues(this.insuranceData);
    } else {
      this.$emit('on-key-change', this.key);
      this.fillApiValuesData();
    }

    if (this.insuranceData?.basicData?.anschriftNeubau) {
      this.fillVersicherungsortItemsFromGruppendaten(this.insuranceData.basicData.anschriftNeubau);
    }

    (this as any).startWatcherActivated();

    this.initPositionsData(this.insuranceData?.basicData);
  },
  beforeDestroy() {
    if (this.isPCUser) return;
    this.onSubmit(false, false);
  },
});
